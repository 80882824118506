<template>
  <div>
    <b-container fluid class="content">
      <b-alert
        :show="countdown"
        dismissible
        :variant="variant"
        @dismissed="countdown = 0"
        @dismiss-count-down="close_alert"
        class="show_alert"
      >
        {{ dismessage }}
      </b-alert>
      <b-card v-if="currpage != 'edit' || cur_opt != 'add'">
        <b-form>
          <b-row v-if="add_dept || add_cate">
            <b-col v-if="add_dept" col lg="3">
              <b-input
                class="form-control"
                v-model="cur_department.Name1"
                :placeholder="$t('Department Name') + '1'"
              />
            </b-col>
            <b-col v-if="add_dept" col lg="3">
              <b-input
                class="form-control"
                v-model="cur_department.Name2"
                :placeholder="$t('Department Name') + '2'"
              />
            </b-col>
            <b-col v-if="add_cate" col lg="3">
              <select
                class="form-control"
                v-model="cur_department_IDn"
                @change="get_category_list('list')"
              >
                <option :value="0">{{ $t("Select Department") }}</option>
                <option
                  v-for="(dpt, idx) in departmentlist"
                  :key="idx"
                  :value="dpt.IDn"
                >
                  {{ dpt.Name1 }} - {{ dpt.Name2 }}
                </option>
              </select>
            </b-col>
            <b-col v-if="add_cate" col lg="3">
              <b-input
                class="form-control"
                v-model="cur_category.Name1"
                :placeholder="$t('Category Name') + '1'"
              />
            </b-col>
            <b-col v-if="add_cate" col lg="3">
              <b-input
                class="form-control"
                v-model="cur_category.Name2"
                :placeholder="$t('Category Name') + '2'"
              />
            </b-col>
            <b-col class="text-center" lg="3">
              <el-button type="primary" @click="add_dept_cate">{{
                add_dept ? $t("Add Department") :
                add_cate ? $t("Add Category") : ""
              }}</el-button>
              <el-button type="primary" @click="cancel_dept_cate">{{
                $t("Cancel")
              }}</el-button>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col>
              <el-tooltip :content="$t('Double click to add')" placement="top" :open-delay="1300" :disabled="!hasUtilityAuth('DepartmentEdit')">
                <select
                  class="form-control"
                  v-model="cur_department_IDn"
                  @change="get_category_list('list')"
                  @dblclick="start_dept"
                >
                  <option :value="0">{{ $t("Select Department") }}</option>
                  <option
                    v-for="(dpt, idx) in departmentlist"
                    :key="idx"
                    :value="dpt.IDn"
                  >
                    {{ dpt.Name1 }} - {{ dpt.Name2 }}
                  </option>
                  <option :value="-1" class="add-option" v-acl:DepartmentEdit.enable.show>{{ "+ " + $t("Department") }}</option>
                </select>
              </el-tooltip>
            </b-col>
            <b-col>
              <el-tooltip :content="$t('Double click to add')" placement="top" :open-delay="1300" v-if="search_categorylist" :disabled="!hasUtilityAuth('CategoryEdit')">
                <select
                  class="form-control"
                  v-model="cur_category_IDn"
                  @change="get_product_list(0)"
                  @dblclick="start_cate"
                >
                  <option :value="0">{{ $t("Select Category") }}</option>
                  <option
                    v-for="(ct) in search_categorylist"
                    :key="ct.IDn"
                    :value="ct.IDn"
                  >
                    {{ ct.Name1 }} - {{ ct.Name2 }}
                  </option>
                  <option :value="-1" class="add-option" v-acl:CategoryEdit.enable.show>{{ "+ " + $t("Category") }}</option>
                </select>
              </el-tooltip>
            </b-col>
            <b-col>
              <select
                class="form-control"
                v-model="cur_status"
                @change="get_product_list(0)"
              >
                <option :value="0">{{ $t("Select Status") }}</option>
                  <option value="Active">Active</option>
                  <option value="Deactive">Deactive</option>
                  <option value="Modifier">Modifier</option>
              </select>
            </b-col>
            <b-col>
              <el-cascader
                :props="{ expandTrigger: 'hover' }"
                :placeholder="$t('Try searching: product')"
                :options="cascader_options"
                :show-all-levels="false"
                v-model="cascader_product"
                @change="cascader_product_change"
                clearable
                filterable
                v-acl:ProductEdit.enable.show
              ></el-cascader>
              <b-input
                class="form-control"
                v-model="cur_name"
                :placeholder="$t('Try searching: product')"
                @keyup="get_product_list_delay(0)"
                v-acl:ProductEdit.enable.hide
              />
            </b-col>
            <b-col class="text-center" lg="4">
              <el-button
                v-if="currpage == 'list'"
                type="primary"
                @click="add_product"
                v-acl:ProductEdit.enable.show
              >{{
                $t("Add Product")
              }}</el-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
      <b-row v-if="currpage == 'list'">
        <b-col>
          <b-card>
            <div></div>
            <p class="p-3" v-if="productlist">
              <el-table
                stripe
                class="table-responsive table"
                header-row-class-name="thead-light"
                :data="productlist"
                @row-click="row_clicked_event"
              >
                <el-table-column label="PLU" prop="PLU"> </el-table-column>
                <el-table-column :label="$t('Name')">
                  <template v-slot="{ row }">
                    {{ row.Name1 }} - {{ row.Name2 }}
                  </template>
                </el-table-column>
                <el-table-column :label="$t('Category')">
                  <template v-slot="{ row }">
                    {{ categorylist[row.CategoryIDn].Name1 }} - {{ categorylist[row.CategoryIDn].Name2 }}
                  </template>
                </el-table-column>
                <el-table-column :label="$t('Tax Class')">
                  <template v-slot="{ row }">
                    <b-form-select
                      class="form-control"
                      v-model="row.TaxTypeIDn"
                      @change="change_row_property(row, 'TaxTypeIDn')"
                      size="sm"
                      v-acl:ProductEdit.enable
                    >
                      <!-- <option value="0"></option> -->
                      <option
                        v-for="(tax, idx) in tax_typelist"
                        :key="idx"
                        :value="tax.IDn"
                      >
                        {{ tax.Name }}
                      </option>
                    </b-form-select>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('Printer')">
                  <template v-slot="{ row }">
                    <b-form-select
                      class="form-control"
                      v-model="row.PrinterIDn"
                      @change="change_row_property(row, 'PrinterIDn')"
                      size="sm"
                      v-acl:ProductEdit.enable
                    >
                      <option
                        v-for="(prt, idx) in printerlist"
                        :key="idx"
                        :value="prt.IDn"
                      >
                        {{ prt.Name }}
                      </option>
                    </b-form-select>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('Price') + ' A'">
                  <template v-slot="{ row }">
                    <b-form-input
                      class="form-control"
                      v-model="row.APrice"
                      @change="change_row_property(row, 'APrice')"
                      @keypress="onlyNumber"
                      size="sm"
                      v-acl:ProductEdit.enable
                    >
                    </b-form-input>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('Status')">
                  <template v-slot="{ row }">
                    <b-form-select
                      class="form-control"
                      v-model="row.Status"
                      @change="change_row_property(row, 'Status')"
                      size="sm"
                      v-acl:ProductEdit.enable
                    >
                      <option value="Active">Active</option>
                      <option value="Deactive">Deactive</option>
                      <option value="Modifier">Modifier</option>
                    </b-form-select>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('Discount')">
                  <template v-slot="{ row }">
                    <b-form-checkbox
                      class="text-center"
                      v-model="row.Discountable"
                      value="True"
                      unchecked-value="False"
                      @change="change_row_property(row, 'Discountable')"
                      v-acl:ProductEdit.enable
                    >
                    </b-form-checkbox>
                  </template>
                </el-table-column>
              </el-table>
              <br />
              <el-row>
                <el-col :span="20">
                  <div>
                    <b-pagination
                      v-model="current_page"
                      :total-rows="product_total"
                      :per-page="per_page"
                    ></b-pagination>
                  </div>
                </el-col>
                <el-col :span="4">
                  <div>
                    <el-dropdown @command="handleCommand">
                      <span class="el-dropdown-link">
                        {{ $t("Max Rows per page") }} {{ this.per_page
                        }}<i class="el-icon-arrow-down el-icon--right"></i>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item
                          v-for="page_option in per_page_option"
                          :key="page_option"
                          :command="page_option"
                          >{{ page_option }}</el-dropdown-item
                        >
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                </el-col>
              </el-row>
            </p>
            <p class="p-3" v-else>
              {{ $t("No Records") }}
            </p>
          </b-card>
        </b-col>
      </b-row>

      <b-row v-if="currpage == 'edit'">
        <b-col v-if="cur_product">
          <b-card no-body>
            <b-card-body>
              <b-form>
                <el-row :gutter="10">
                  <el-col :span="24">
                    {{ cur_opt == "add" ? 0 : cur_product.IDn }}
                  </el-col>
                </el-row>
                <el-row :gutter="10">
                  <el-col :span="18">
                    <el-row :gutter="10">
                      <el-col :span="8">
                        <base-input :label="$t('Department')">
                          <select
                            class="form-control"
                            v-model="cur_product.DepartmentIDn"
                            @change="get_category_list('product')"
                          >
                            <option
                              v-for="(dt, idx) in departmentlist"
                              :key="idx"
                              :value="dt.IDn"
                            >
                              {{ dt.Name1 }} - {{ dt.Name2 }}
                            </option>
                          </select>
                        </base-input>
                      </el-col>
                      <el-col :span="8">
                        <base-input :label="$t('Category')">
                          <select
                            class="form-control"
                            v-if="product_categorylist"
                            v-model="cur_product.CategoryIDn"
                            @change="category_product_changed"
                          >
                            <option
                              v-for="(dt) in product_categorylist"
                              :key="dt.IDn"
                              :value="dt.IDn"
                            >
                              {{ dt.Name1 }} - {{ dt.Name2 }}
                            </option>
                          </select>
                        </base-input>
                      </el-col>
                      <el-col :span="8">
                        <base-input :label="$t('Status')">
                          <select class="form-control" v-model="cur_product.Status">
                            <option>Active</option>
                            <option>Deactive</option>
                            <option>Modifier</option>
                            <!-- For All modifier -->
                          </select>
                        </base-input>
                      </el-col>

                      <el-col :span="8">
                        <base-input :label="$t('Name') + '1'">
                          <b-input
                            :placeholder="$t('Name') + '1'"
                            class="form-control"
                            v-model="cur_product.Name1"
                            required
                          />
                        </base-input>
                      </el-col>
                      <el-col :span="8">
                        <base-input :label="$t('Name') + '2'">
                          <b-input
                            :placeholder="$t('Name') + '2'"
                            class="form-control"
                            v-model="cur_product.Name2"
                          />
                        </base-input>
                      </el-col>
                      <el-col :span="8">
                        <base-input :label="$t('Menu Key')">
                          <b-input
                            :placeholder="$t('Menu Key or Short Name')"
                            class="form-control"
                            v-model="cur_product.ShortName"
                          />
                        </base-input>
                      </el-col>

                      <el-col :span="8">
                        <base-input :label="$t('Description') + '1'">
                          <b-input
                            :placeholder="$t('Description') + '1'"
                            class="form-control"
                            v-model="cur_product.Description1"
                            required
                          />
                        </base-input>
                      </el-col>
                      <el-col :span="8">
                        <base-input :label="$t('Description') + '2'">
                          <b-input
                            :placeholder="$t('Description') + '2'"
                            class="form-control"
                            v-model="cur_product.Description2"
                          />
                        </base-input>
                      </el-col>
                    </el-row>
                    <el-row :gutter="10">
                      <el-col :span="8">
                        <base-input label="PLU">
                          <b-input
                            placeholder="PLU"
                            class="form-control disabled-input"
                            v-model="cur_product.PLU"
                            disabled
                          />
                        </base-input>
                      </el-col>
                      <el-col :span="8">
                        <base-input :label="$t('Barcode')">
                          <b-input
                            :placeholder="$t('Barcode')"
                            class="form-control"
                            v-model="cur_product.Barcode"
                          />
                        </base-input>
                      </el-col>
                    </el-row>
                  </el-col>
                  <el-col :span="6">
                    <el-container>
                      <el-main>
                        <div v-bind:style="{height: '15rem',}">
                          <img
                            v-if="url || cur_product.imagefile"
                            :src="url ? url : apiBaseUrl + cur_product.imagefile"
                            class="rounded picimg"
                            @click="deleteImage"
                          />
                        </div>
                      </el-main>
                      <el-footer>
                        <el-button
                          v-if="hasLdwUpload"
                          class="upload_ldw"
                          @click="upload_ldw"
                          size="mini"
                          type="primary"
                        >{{ $t("Update LDW") }}</el-button>
                        <base-input :label="$t('Image')">
                          <b-form-file
                            id='image_upload'
                            v-model="image1"
                            :state="Boolean(image1)"
                            :placeholder="$t('Choose a file or drop it here') + '...'"
                            :drop-placeholder="$t('Drop file here') + '...'"
                            :browse-text="$t('Browse')"
                            @input='image_upload_input'
                          ></b-form-file>
                          <b-popover
                            :disabled='true'
                            ref="image_popover"
                            target='image_upload'
                            placement='topright'
                            :content="$t('Image size is too big. Make it less than 7MB before uploading.')"
                          ></b-popover>
                        </base-input>
                      </el-footer>
                    </el-container>
                  </el-col>
                </el-row>
                <el-tabs value="Price">
                  <el-tab-pane :label="$t('Price') + ' / ' + $t('Tax')" name="Price">
                    <el-row :gutter="10">
                      <el-col :span="4">
                        <base-input :label="$t('Tax Class')">
                          <el-select
                            v-model="cur_product_taxTypeIDn"
                            style="width: 100%; "
                            @change="tax_type_change">
                            <!-- <el-option :value="0" :label="' '"></el-option> -->
                            <el-option
                              v-for="(tax, idx) in tax_typelist"
                              :key="idx"
                              :value="tax.IDn"
                              :label="tax.Name">
                              <span style="float: left">{{ tax.Name }}</span>
                              <a @click="edit_productclass(tax.IDn)">
                                <span style="float: right; color: #8492a6; font-size: 13px">{{ $t("Edit") }}</span>
                              </a>
                            </el-option>
                            <el-option :value="-1" label=" " class="add-option">{{ "+ " + $t("Tax Class") }}</el-option>
                          </el-select>
                        </base-input>
                        <b-modal :title="$t('Tax Class')" size="lg" id="modal_productclass" hide-footer>
                          <el-steps :active="active_step" finish-status="success" align-center>
                            <el-step :title="$t('Step') + ' 1'"></el-step>
                            <el-step :title="$t('Step') + ' 2'"></el-step>
                            <el-step :title="$t('Step') + ' 3'"></el-step>
                          </el-steps>
                          <!-- <el-carousel
                            ref="productclass_carousel"
                            height="20rem"
                            indicator-position="none"
                            :autoplay="false"
                            arrow="never"
                            style="padding-top: 5rem;">
                            <el-carousel-item>
                            </el-carousel-item>
                          </el-carousel> -->
                          <div v-if="cur_tax_type">
                            <hr>
                            <label class="step">{{ $t("Step")+" 1: "+$t("Tax Class") }}</label>
                            <b-row>
                              <b-col>
                                <base-input :class="cur_tax_type_validation.tax_type_name ? 'required' : ''">
                                  <b-input
                                    placeholder="Name"
                                    class="form-control"
                                    style="width: 10rem;
                                    border-top-width: 0;
                                    border-left-width: 0;
                                    border-right-width: 0;
                                    border-bottom-left-radius: 0;
                                    border-bottom-right-radius: 0;
                                    background-color: white;"
                                    autocomplete="off" 
                                    @focus="step_focus(0)"
                                    v-model="cur_tax_type.Name"
                                    :disabled="cur_tax_type.IDn ? true : false"
                                  >
                                  </b-input>
                                </base-input>
                              </b-col>
                            </b-row>
                            <b-row>
                              <b-col>
                                <base-input :label="$t('Remarks')">
                                  <textarea
                                    :placeholder="$t('Remarks')"
                                    class="form-control"
                                    style="height: 5rem; resize: none;"
                                    @focus="step_focus(0)"
                                    v-model="cur_tax_type.Remarks"
                                  />
                                </base-input>
                              </b-col>
                            </b-row>
                            <hr>
                            <label class="step">{{ $t("Step")+" 2: "+$t("Choose Province") }}</label>
                            <el-row :gutter="10">
                              <el-col :span="12">
                                <base-input
                                  :label="$t('Country')"
                                  :class="cur_tax_type_validation.tax_group_country ? 'required' : ''"
                                >
                                  <select
                                    class="form-control"
                                    @focus="step_focus(1)"
                                    v-model="cur_country_idn"
                                    @change="country_change"
                                  >
                                    <option
                                      v-for="(country) in country_list"
                                      :key="country.IDn"
                                      :value="country.IDn"
                                    >
                                      {{ country.Country }}
                                    </option>
                                  </select>
                                </base-input>
                              </el-col>
                              <el-col :span="12">
                                <base-input
                                  :label="$t('Province')"
                                  :class="cur_tax_type_validation.tax_group_province ? 'required' : ''"
                                >
                                  <select
                                    class="form-control"
                                    value="British Columbia"
                                    @focus="step_focus(1)"
                                    v-model="cur_province_idn"
                                    @change="province_change"
                                  >
                                    <option
                                      v-for="(province) in cur_province_list"
                                      :key="province.IDn"
                                      :value="province.IDn"
                                    >
                                      {{ province.Province }}
                                    </option>
                                  </select>
                                </base-input>
                              </el-col>
                            </el-row>
                          </div>
                          <div v-if="cur_tax_group">
                            <hr>
                            <label class="step">{{ $t("Step")+" 3: "+$t("Choose Tax") }}</label>
                            <b-row>
                              <b-col>
                                <base-input
                                  :label="$t('Tax List')"
                                  :class="cur_tax_type_validation.tax_group_tax_list ? 'required' : ''"
                                >
                                  <el-tag 
                                    v-for="tax in tax_list"
                                    :key="tax.ID"
                                    :value="tax.ID"
                                    :style="el_tag_style_object[tax.ID]"
                                    class="tax-tag"
                                    @click.native="tag_clicked(tax.ID)">
                                    <!-- {{ tax.TaxName + " " + (tax.ValueType == "$" ? "$" : "") + parseFloat(tax.TaxValue).toFixed(2) + (tax.ValueType == "%" ? "%" : "") }} -->
                                    {{ format_taxinfo(tax) }}
                                  </el-tag>
                                </base-input>
                              </b-col>
                            </b-row>
                            <b-row v-if="cur_tax_group">
                              <b-col>
                                <base-input :label="$t('Remarks')">
                                  <textarea
                                    :placeholder="$t('Remarks')"
                                    class="form-control"
                                    style="height: 5rem; resize: none;"
                                    @focus="step_focus(2)"
                                    v-model="cur_tax_group.Remarks"
                                  />
                                </base-input>
                              </b-col>
                            </b-row>
                          </div>
                          <div style="text-align: right;">
                            <el-button
                              style="margin-top: 12px;"
                              @click="cancel_step_clicked"
                              type="primary"
                            >{{ $t("Cancel") }}</el-button>
                            <el-button
                              style="margin-top: 12px;"
                              @click="finish_step_clicked"
                              type="primary"
                            >{{ $t("Confirm") }}</el-button>
                          </div>
                        </b-modal>
                      </el-col>
                      <el-col :span="4" style="text-align: right;">
                        <el-tooltip :content="$t('Add Price')" placement="top" v-if="!is_vip_category">
                          <el-button
                            style="margin-top: 2rem; margin-right: 0.5vw;"
                            type="primary"
                            icon="el-icon-plus"
                            @click="add_price"
                            :disabled="!enable_add_price"
                            circle
                          >
                          </el-button>
                        </el-tooltip>
                      </el-col>
                      <el-col :span="4">
                        <base-input :label="is_vip_category ? $t('Pay Amount') : $t('Price') + ' A (MSRP)'">
                          <b-input
                            placeholder="Price"
                            class="form-control"
                            @keypress="onlyNumber"
                            v-model="cur_product.APrice"
                          />
                        </base-input>
                      </el-col>
                      <el-col v-if="is_vip_category || price_mark > 1" :span="4">
                        <base-input :label="is_vip_category ? $t('Load Amount'): $t('Price') + ' B'">
                          <b-input
                            placeholder="Price"
                            class="form-control"
                            @keypress="onlyNumber"
                            v-model="cur_product.BPrice"
                          />
                        </base-input>
                      </el-col>
                      <el-col v-if="is_vip_category || price_mark > 2" :span="4">
                        <base-input :label="is_vip_category ? $t('Load Points') : $t('Price') + ' C'">
                          <b-input
                            placeholder="Price"
                            class="form-control"
                            @keypress="onlyNumber"
                            v-model="cur_product.CPrice"
                          />
                        </base-input>
                      </el-col>
                      <el-col v-if="!is_vip_category && price_mark > 3" :span="4">
                        <base-input :label="$t('Price') + ' D'">
                          <b-input
                            placeholder="Price"
                            class="form-control"
                            @keypress="onlyNumber"
                            v-model="cur_product.DPrice"
                          />
                        </base-input>
                      </el-col>
                      <el-col v-if="!is_vip_category && price_mark > 4" :span="4">
                        <base-input :label="$t('Price') + ' E'">
                          <b-input
                            placeholder="Price"
                            class="form-control"
                            @keypress="onlyNumber"
                            v-model="cur_product.EPrice"
                          />
                        </base-input>
                      </el-col>
                      <el-col v-if="!is_vip_category  && price_mark > 5" :span="4">
                        <base-input :label="$t('Price') + ' F'">
                          <b-input
                            placeholder="Price"
                            class="form-control"
                            @keypress="onlyNumber"
                            v-model="cur_product.FPrice"
                          />
                        </base-input>
                      </el-col>
                      <el-col v-if="!is_vip_category && price_mark > 6" :span="4">
                        <base-input :label="$t('Price') + ' G'">
                          <b-input
                            placeholder="Price"
                            class="form-control"
                            @keypress="onlyNumber"
                            v-model="cur_product.GPrice"
                          />
                        </base-input>
                      </el-col>
                      <el-col v-if="!is_vip_category && price_mark > 7" :span="4">
                        <base-input :label="$t('Price') + ' H'">
                          <b-input
                            placeholder="Price"
                            class="form-control"
                            @keypress="onlyNumber"
                            v-model="cur_product.HPrice"
                          />
                        </base-input>
                      </el-col>
                      <el-col v-if="!is_vip_category && price_mark > 8" :span="4">
                        <base-input :label="$t('Price') + ' I'">
                          <b-input
                            placeholder="Price"
                            class="form-control"
                            @keypress="onlyNumber"
                            v-model="cur_product.IPrice"
                          />
                        </base-input>
                      </el-col>
                      <el-col v-if="!is_vip_category" :span="4">
                        <base-input :label="$t('Points')">
                          <b-input
                            placeholder="Porints"
                            class="form-control"
                            @keypress="onlyNumber"
                            v-model="cur_product.JPrice"
                          />
                        </base-input>
                      </el-col>
                    </el-row>
                  </el-tab-pane>
                  <el-tab-pane :label="$t('Modifier')" name="Modifier">
                    <el-row :gutter="10">
                      <el-col :span="24">
                        <b-form-checkbox-group
                          v-model="cur_product.ModifierGroups"
                          inline="true"
                        >
                          <b-form-checkbox
                            v-for="mod in modifierlist"
                            :key="mod.IDn"
                            :value="mod.IDn"
                          >
                            <span class="select_text">
                              {{ mod.Name1 }} - {{ mod.Name2 }}
                            </span>
                          </b-form-checkbox>
                        </b-form-checkbox-group>
                      </el-col>
                    </el-row>
                  </el-tab-pane>
                  <el-tab-pane :label="$t('Others')" name="Others">
                    <el-row :gutter="10">
                      <el-col :span="6">
                        <base-input :label="$t('Printer')">
                          <select
                            class="form-control"
                            v-model="cur_product.PrinterIDn"
                          >
                            <option></option>
                            <option
                              v-for="(prt, idx) in printerlist"
                              :key="idx"
                              :value="prt.IDn"
                            >
                              {{ prt.Name }}
                            </option>
                          </select>
                        </base-input>
                      </el-col>
                      <el-col :span="6">
                        <base-input :label="$t('Minimum Quantity')">
                          <b-input
                            :placeholder="$t('Minimum Quantity')"
                            class="form-control"
                            v-model="cur_product.MinQty"
                              @keypress="onlyNumber"
                          />
                        </base-input>
                      </el-col>
                      <el-col :span="6">
                        <base-input :label="$t('Weigh')">
                          <el-col :span="8" class="text-center">
                            <el-switch
                              style="margin-top: 0.75rem;"
                              v-model="cur_product.Weigh"
                              active-value="Y"
                              inactive-value="N"
                            >
                            </el-switch>
                          </el-col>
                          <el-col :span="16" v-if="cur_product.Weigh == 'Y'">
                            <select
                              class="form-control"
                              v-model="cur_product.PackageWeightUnit"
                            >
                              <option></option>
                              <option
                                v-for="unit in weigh_unit_list"
                                :key="unit.IDn"
                                :value="unit.Unit"
                                >{{ unit.Unit }}
                              </option>
                            </select>
                          </el-col>
                        </base-input>
                      </el-col>
                    </el-row>
                    <el-row class="layout-row" :gutter="10">
                      <el-col :span="6">
                        <el-switch
                          v-model="cur_product.IsOpenPrice"
                          :active-text="$t('Open Price')"
                          :active-value="1"
                          :inactive-value="0"
                        >
                        </el-switch>
                      </el-col>
                      <el-col :span="6">
                        <el-switch
                          v-model="cur_product.NonRevenue"
                          :active-text="$t('None Revenue')"
                          :active-value="1"
                          :inactive-value="0"
                        >
                        </el-switch>
                      </el-col>
                      <el-col :span="6">
                        <el-switch
                          v-model="cur_product.Discountable"
                          :active-text="$t('Discountable')"
                          active-value="True"
                          inactive-value="False"
                        >
                        </el-switch>
                      </el-col>
                      <el-col :span="6">
                        <el-switch
                          v-model="cur_product.Recommended"
                          :active-text="$t('Recommended')"
                          :active-value="1"
                          :inactive-value="0"
                        >
                        </el-switch>
                      </el-col>
                      <!-- <el-col :span="6">
                        <base-input :label="$t('AverageCost')">
                          <b-input
                            :placeholder="$t('AverageCost')"
                            class="form-control"
                            v-model="cur_product.AverageCost"
                          />
                        </base-input>
                      </el-col>
                      <el-col :span="6">
                        <base-input :label="$t('LatestPrice')">
                          <b-input
                            :placeholder="$t('LatestPrice')"
                            class="form-control"
                            v-model="cur_product.LatestPrice"
                          />
                        </base-input>
                      </el-col> -->
                    </el-row>
                    <el-row :gutter="10">
                      <el-col :span="24">
                        <base-input :label="$t('Remarks')">
                          <b-input
                            :placeholder="$t('Remarks')"
                            class="form-control"
                            v-model="cur_product.Remarks"
                          />
                        </base-input>
                      </el-col>
                    </el-row>
                  </el-tab-pane>
                </el-tabs>

                <b-row class="mt-3">
                  <b-col class="text-center">
                    <!-- lisa modify for the transfer of category when back to list page -->
                    <!-- <el-button type="primary" @click="form_cancel">{{ -->
                    <el-button type="primary" @click="Back_to_list">{{
                      $t("Back")
                    }}</el-button>
                  </b-col>
                  <b-col class="text-center">
                    <el-button type="primary" @click="form_submit($event, 0)">
                      <span v-if="cur_product.IDn">{{ $t("Update") }}</span>
                      <span v-else>{{ $t("Add") }}</span>
                    </el-button>
                  </b-col>
                  <b-col class="text-center" v-if="next_row">
                    <el-button type="primary" @click="form_submit($event, 1)">{{
                      $t("Update") + " & " + $t("Next")
                    }}</el-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import {
  Table,
  TableColumn,
  Button,
  Collapse,
  CollapseItem,
  Row,
  Col,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Cascader,
  Tabs,
  TabPane,
  Container,
  Main,
  Footer,
  Switch,
  Checkbox,
  Tooltip,
  Select,
  Option,
  Carousel,
  CarouselItem,
  Steps,
  Step,
  CheckboxButton,
  CheckboxGroup,
  Tag,
} from "element-ui";
import { getStore, setStore } from "../../util/helper";
import Sortable from "sortablejs";
import { hasUtilityAuth } from "../../directives/acl";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Row.name]: Row,
    [Col.name]: Col,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Cascader.name]: Cascader,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [Container.name]: Container,
    [Main.name]: Main,
    [Footer.name]: Footer,
    [Switch.name]: Switch,
    [Checkbox.name]: Checkbox,
    [Tooltip.name]: Tooltip,
    [Select.name]: Select,
    [Option.name]: Option,
    [Carousel.name]: Carousel,
    [CarouselItem.name]: CarouselItem,
    [Steps.name]: Steps,
    [Step.name]: Step,
    [CheckboxButton.name]: CheckboxButton,
    [CheckboxGroup.name]: CheckboxGroup,
    [Tag.name]: Tag,
  },
  data() {
    return {
      active_step: 0,
      url: "",
      activeNames: [],
      currpage: "list",
      cur_product: null,
      cur_product_taxTypeIDn: null,
      cur_opt: null,
      next_row: null,
      new_product: {
        IDn: 0,
        ID: 0,
        Deleted: 0,
        TaxTypeIDn: 1,
        SortOrder: "999",
        PrinterIDn: "1",
        VendorIDn: 0,
        IsCombo: 0,
        NonRevenue: 0,
        DepartmentIDn: 0,
        BaseProductIDn: 3,
        IsOpenPrice: 0,
        CategoryID: "",
        CategoryIDn: 0,
        PLU: "",
        Name1: "",
        Name2: "",
        ShortName: "",
        BtnColor: "#cad1d7",
        Description1: "",
        Description2: "",
        Brand: "",
        Barcode: "",
        SKU: "",
        Status: "Active",
        OriginCountry: "",
        Remarks: "",
        Unit: "",
        UnitName: "",
        Weigh: "N",
        PackL: "1",
        PackM: "1",
        PackS: "1",
        Size: "",
        DimL: "0",
        DimM: "0",
        DimS: "0",
        DimUnit: "CM",
        GrossWeight: 0,
        NetWeight: "0",
        PackageWeight: "1",
        PackageWeightUnit: "",
        BoxSize: "",
        Attribute1: "",
        Attribute2: "",
        HasSerialNumber: 0,
        MinQty: 1,
        InventoryAutoUpdate: 1,
        Zoning: "",
        Attribute3: "",
        OnlineProduct: "",
        FixPrice: "",
        AisFixed: "",
        BisFixed: "",
        CisFixed: "",
        DisFixed: "",
        EisFixed: "",
        FisFixed: "",
        GisFixed: "",
        HisFixed: "",
        IisFixed: "",
        JisFixed: "",
        OnLinePriceSet: "",
        OnlineShowPrice: "True",
        APrice: "",
        BPrice: "",
        CPrice: "",
        DPrice: "",
        EPrice: "",
        FPrice: "",
        GPrice: "",
        HPrice: "",
        IPrice: "",
        JPrice: "",
        Discountable: "True",
        Type: "Product",
        ExpiryPeriod: "",
        ExpiryPeriodD: "",
        ExpiryPeriodU: "",
        WarrantyPeriod: "",
        WarrantyPeriodD: "",
        WarrantyPeriodU: "",
        seq: "1",
        imagefile: '',
        BaseInbound: 1,
        BaseOutbound: 1,
        Recommended: 0,
        ModifierGroups: [],
      },
      departmentlist: null,
      categorylist: null,
      search_categorylist: null,
      product_categorylist: null,
      productlist: null,
      printerlist: null,
      modifierlist: null,
      tax_typelist: null,

      saved_department_IDn: 0,
      saved_category_IDn: 0,
      saved_status: 0,

      cur_department_IDn: 0,
      cur_category_IDn: 0,
      cur_status: 0,
      cur_name: "",

      delay_timer: null,

      dismessage: "",
      countdown: 0,
      variant: "success",

      cur_product_IDn: 0,
      current_page: 1,
      product_total: 0,
      per_page: 20,
      PrinterIDn: 0,
      IsCombo: -1,
      is_vip_category: false,
      per_page_option: [10, 20, 30, 100, 500],
      btnselectstyle: { backgroundColor: "#cad1d7" },

      image1: null,

      cascader_product: [],
      cascader_options: [],
      cascader_products: [],
      cascader_item: [],

      token: "",
      user_id: 0,
      is_retail: null,
      is_wholesale: null,

      sortable: null,
      isSortableEnabled: false,
      
      hasLdwUpload: false,
      
      editing_idx: -1,
      cur_item: {},

      add_dept: false,
      add_cate: false,
      cur_department: null,
      cur_category: null,
      new_department: {
        IDn: "",
        ID: "",
        PLU: "",
        Name1: "",
        Name2: "",
        SortOrder: "",
        BtnColor: "#cad1d7",
        Remarks: "",
        imagefile: "",
      },
      new_category: {
        IDn: "",
        ID: "",
        departmentIDn: "",
        departmentID: "",
        PLU: "",
        Name1: "",
        Name2: "",
        SortOrder: "",
        BtnColor: "#cad1d7",
        Remarks: "",
        imagefile: 1,

        Modifiers: [],
      },
      new_product_class: {
        // tax group
        IDn: 0,
        Name: "",
        TaxTypeIDn: 1,
        TaxList: "",
        Remarks: "",
        // CreateDateTime: null,
        // UpdateDateTime: null,
        Status: 1,
        CountryIDn: 0,
        ProvinceIDn: 0,
      },
      el_tag_unchecked: {
        color: "#409EFF",
        borderColor: "rgba(64,158,255,.2)",
        borderColorHover: "rgba(64,158,255,.3)",
        backgroundColor: "rgba(64,158,255,.1)",
        backgroundColorHover: "rgba(64,158,255,.2)",
      },
      el_tag_checked: {
        color: "white",
        borderColor: "rgba(64,158,255)",
        borderColorHover: "rgba(64,158,255,.8)",
        backgroundColor: "rgba(64,158,255,.8)",
        backgroundColorHover: "rgba(64,158,255,.7)",
      },
      cur_tax_type: null,
      cur_tax_type_validation: null,
      cur_country_idn: 0,
      cur_province_idn: 0,
      cur_tax_group: null,
      cur_tax_group_list: [],
      tax_list: [],
      cur_tax_checked: [],
      country_list: [],
      cur_province_list: [],
      new_tax_type: {
        IDn: 0,
        Name: "",
        Remarks: "",
        Status: 1,
      },
      new_tax_group: {
        IDn: 0,
        Name: "",
        TaxTypeIDn: 0,
        TaxList: "",
        Remarks: "",
        Status: 1,
        CountryIDn: 0,
        ProvinceIDn: 0,
      },
      new_tax_type_validation: {
        tax_type_name: false,
        tax_group_country: false,
        tax_group_province: false,
        tax_group_tax_list: false,
      },
    };
  },
  watch: {
    current_page: function (newval, oldval) {
      this.get_product_list(1);
    },
    'cur_product.Recommended': function(val){
      if (this.cur_product) {
        this.cur_product.Recommended = parseInt(val);
      }
    },
    deep: true,
    
    'cur_product.IsOpenPrice': function(val){
      if (this.cur_product) {
        this.cur_product.IsOpenPrice = parseInt(val);
      }
    },
    deep: true,
  },
  computed: {
    apiBaseUrl: function () {
      return axios.defaults.baseURL + "/Api";
    },
    price_mark: function () {
      return this.get_price_mark();
    },
    enable_add_price: function () {
      return this.get_enable_add_price();
    },
    count_unit_list: function () {
      return this.get_typed_unit_list("Count");
    },
    weigh_unit_list: function () {
      return this.get_typed_unit_list("Weight");
    },
    el_tag_style_object: function () {
      // return this.checked0 ? 
      //   {
      //     '--color': this.el_tag_checked.color,
      //     '--border-color': this.el_tag_checked.borderColor,
      //     '--border-color-hover': this.el_tag_checked.borderColorHover,
      //     '--background-color': this.el_tag_checked.backgroundColor,
      //     '--background-color-hover': this.el_tag_checked.backgroundColorHover,
      //   } : {
      //     '--color': this.el_tag_unchecked.color,
      //     '--border-color': this.el_tag_unchecked.borderColor,
      //     '--border-color-hover': this.el_tag_unchecked.borderColorHover,
      //     '--background-color': this.el_tag_unchecked.backgroundColor,
      //     '--background-color-hover': this.el_tag_unchecked.backgroundColorHover,
      //   }
      return this.get_el_tag_style_object_array();
    },
  },
  methods: {
    hasUtilityAuth,
    format_taxinfo(tax) {
      let tax_name = tax.TaxName;
      let tax_value = parseFloat(tax.TaxValue).toString();
      let tax_value_type = tax.ValueType;
      return tax_name + (
        tax_value_type == "$" ? "(" + tax_value_type + tax_value + ")" :
        tax_value_type == "%" ? "(" + tax_value + tax_value_type + ")" :
        ""
      );
    },
    get_el_tag_style_object_array() {
      let rtn = {};
      for (const p in this.cur_tax_checked) {
        rtn[p] = this.get_el_tag_style_object(this.cur_tax_checked[p]);
      }
      return rtn;
    },
    get_el_tag_style_object (checked = false) {
      return checked ? 
        {
          '--color': this.el_tag_checked.color,
          '--border-color': this.el_tag_checked.borderColor,
          '--border-color-hover': this.el_tag_checked.borderColorHover,
          '--background-color': this.el_tag_checked.backgroundColor,
          '--background-color-hover': this.el_tag_checked.backgroundColorHover,
        } : {
          '--color': this.el_tag_unchecked.color,
          '--border-color': this.el_tag_unchecked.borderColor,
          '--border-color-hover': this.el_tag_unchecked.borderColorHover,
          '--background-color': this.el_tag_unchecked.backgroundColor,
          '--background-color-hover': this.el_tag_unchecked.backgroundColorHover,
        }
    },
    get_cur_tax_checked(that) {
      that.cur_tax_checked = {};
      for(let i = 0; i < that.tax_list.length; i++) {
        that.cur_tax_checked[that.tax_list[i]["ID"]] = 
          (that.cur_tax_group && that.cur_tax_group["TaxList"].includes(that.tax_list[i]["ID"])) ? 
          true : false;
      }
    },
    apply_cur_province_idn(that) {
      if (that.cur_province_idn) {
        if (Object.keys(that.cur_tax_group_list).includes(that.cur_province_idn.toString())) {
          that.cur_tax_group = that.cur_tax_group_list[that.cur_province_idn];
        } else {
          let tmp = JSON.parse(JSON.stringify(that.new_tax_group));
          tmp["TaxTypeIDn"] = that.cur_tax_type["IDn"];
          tmp["CountryIDn"] = that.cur_country_idn;
          tmp["ProvinceIDn"] = that.cur_province_idn;
          that.cur_tax_group = tmp;
          that.cur_tax_group_list[that.cur_tax_group["ProvinceIDn"]] = that.cur_tax_group;
        }
      } else if (Object.keys(that.cur_tax_group_list).length > 0) {
        that.cur_tax_group = that.cur_tax_group_list[Object.keys(that.cur_tax_group_list)[0]];
        that.cur_country_idn = that.cur_tax_group["CountryIDn"];
        that.cur_province_idn = that.cur_tax_group["ProvinceIDn"];
      } else {
        that.cur_tax_group = null;
      }
    },
    change_province() {
      this.apply_cur_province_idn(this);
      this.get_cur_tax_checked(this);
    },
    country_change() {
      let func = () => {
        for (let i = 0; i < this.country_list.length; i++) {
          if (this.country_list[i]["IDn"] == this.cur_country_idn) {
            this.cur_province_list = JSON.parse(JSON.stringify(this.country_list[i]["Provinces"]));
            break;
          }
        }
        this.change_province();
        this.cur_tax_type_validation = JSON.parse(JSON.stringify(this.new_tax_type_validation));
      };
      if (this.cur_tax_type && this.cur_tax_group) {
        this.no_update_check(func, () => {
          this.cur_country_idn = this.cur_tax_group["CountryIDn"];
          this.cur_province_idn = this.cur_tax_group["ProvinceIDn"];
        });
      } else {
        func();
      }
    },
    province_change() {
      let func = () => {
        this.change_province();
        this.cur_tax_type_validation = JSON.parse(JSON.stringify(this.new_tax_type_validation));
      };
      if (this.cur_tax_type && this.cur_tax_group) {
        this.no_update_check(func, () => {
          this.cur_country_idn = this.cur_tax_group["CountryIDn"];
          this.cur_province_idn = this.cur_tax_group["ProvinceIDn"];
        });
      } else {
        func();
      }
    },
    tax_checked_to_list() {
      let tax_list = "";
      for (const p in this.cur_tax_checked) {
        if (this.cur_tax_checked[p]){
          tax_list += (tax_list ? ("," + p) : p);
        }
      }
      return tax_list;
    },
    duplicate_object(obj) {
      let tmp = {};
      for (const p in obj){
        tmp[p] = obj[p];
      }
      return tmp;
    },
    step_focus(step = 0) {
      // console.log("Got focus.");
      if (step >= 0 && step <= 2 && this.active_step < step)
        this.active_step = step;
    },
    tag_clicked(tax_idn) {
      // console.log("tag_clicked");
      this.cur_tax_checked[tax_idn] = !this.cur_tax_checked[tax_idn];
      this.cur_tax_checked = this.duplicate_object(this.cur_tax_checked);
      this.cur_tax_group.TaxList = this.tax_checked_to_list();
      this.step_focus(2);
    },
    tax_type_change(value) {
      if (value > 0){
        this.cur_product.TaxTypeIDn = value;
      } else if (value < 0) {
        // console.log("add Tax class");
        this.get_product_class(0, (that) => {
          // that.cur_product.TaxTypeIDn = 0;
          that.cur_product_taxTypeIDn = that.cur_product.TaxTypeIDn;
          that.$bvModal.show('modal_productclass');
          that.active_step = 0;
          that.cur_tax_type_validation = JSON.parse(JSON.stringify(that.new_tax_type_validation));
        });
      }
    },
    edit_productclass(tax_type_idn){
      // console.log('edit_productclass clicked');
      this.get_product_class(tax_type_idn, (that) => {
        that.$bvModal.show('modal_productclass');
        that.active_step = 0;
        that.cur_tax_type_validation = JSON.parse(JSON.stringify(that.new_tax_type_validation));
      });
    },
    no_update_check(func = () => {}, func_2 = () => {}) {
      let no_update = false;
      let tmp = JSON.parse(JSON.stringify(this.new_tax_type_validation));
      if (!this.cur_tax_type || !this.cur_tax_type.Name) {
        tmp.tax_type_name = true;
      }
      if (this.cur_tax_group) {
        if (!this.cur_tax_group.ProvinceIDn) {
          tmp.tax_group_province = true;
          if (!this.cur_tax_group.CountryIDn) {
            tmp.tax_group_country = true;
          }
        }
        if (!this.cur_tax_group.TaxList) {
          tmp.tax_group_tax_list = true;
        }
      } else {
        if (!this.cur_province_idn) {
          tmp.tax_group_province = true;
        }
        if (!this.cur_country_idn) {
          tmp.tax_group_country = true;
        }
        tmp.tax_group_tax_list = true;
      }
      for (const p in tmp) {
        no_update |= tmp[p];
      }
      this.cur_tax_type_validation = tmp;
      if (!no_update) {
        func();
      } else {
        func_2();
      }
    },
    finish_step_clicked() {
      this.no_update_check(() => {
        this.save_product_class((that) => {
          that.$bvModal.hide('modal_productclass');
        });
      });
    },
    cancel_step_clicked() {
      this.$bvModal.hide('modal_productclass');
    },
    split_period(product) {
      if (product.ExpiryPeriod) {
        product.ExpiryPeriodD = product.ExpiryPeriod.substring(0, product.ExpiryPeriod.length - 1);
        product.ExpiryPeriodU = product.ExpiryPeriod.slice(product.ExpiryPeriod.length - 1);
      } else {
        product.ExpiryPeriodD = "";
        product.ExpiryPeriodU = "";
      }
      if (product.WarrantyPeriod) {
        product.WarrantyPeriodD = product.WarrantyPeriod.substring(0, product.WarrantyPeriod.length - 1);
        product.WarrantyPeriodU = product.WarrantyPeriod.slice(product.WarrantyPeriod.length - 1);
      } else {
        product.WarrantyPeriodD = "";
        product.WarrantyPeriodU = "";
      }
    },
    start_dept() {
      // console.log('add Department');
      if (!hasUtilityAuth('DepartmentEdit')) return;
      this.cur_department_IDn = 0;
      this.cur_category_IDn = 0;
      this.get_product_list(0);
      this.cur_department = JSON.parse(JSON.stringify(this.new_department));
      this.add_dept = true;
    },
    start_cate() {
      // console.log('add Category');
      if (!hasUtilityAuth('CategoryEdit')) return;
      this.cur_category_IDn = 0;
      this.get_product_list(0);
      this.cur_category = JSON.parse(JSON.stringify(this.new_category));
      if (this.cur_department_IDn) {
        this.cur_category.DepartmentIDn = this.cur_department_IDn;
      }
      this.add_cate = true;
    },
    cancel_dept_cate() {
      this.cur_department = null;
      this.cur_category = null;
      this.add_dept = false;
      this.add_cate = false;
    },
    add_dept_cate() {
      if (this.add_dept) {
        this.submit_department((that) => {
          that.get_product_list(0);
        });
      } else if (this.add_cate) {
        this.submit_category((that) => {
          that.get_product_list(0);
        });
      }
      this.cancel_dept_cate();  
    },
    async submit_department(func = () => {}) {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      // bodyFormData.append("opt", this.cur_opt);
      bodyFormData.append("opt", "add");
      var that = this;
      for (var key in this.cur_department) {
        bodyFormData.append(key, this.cur_department[key]);
      }
      // bodyFormData.append("image1", this.image1);
      bodyFormData.append("image1", null);

      axios({
        method: "post",
        url: "/Api/Web/Department/edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              // that.$router.push("/store");
              // that.$router.go();
              if (rt.data.IDn) {
                that.cur_department_IDn = rt.data.IDn;
              }
              func(that);
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async submit_category(func) {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      // bodyFormData.append("opt", this.cur_opt);
      bodyFormData.append("opt", "add");
      var that = this;
      for (var key in this.cur_category) {
        bodyFormData.append(key, this.cur_category[key]);
      }
      if (!this.cur_category.imagefile) bodyFormData.append('imagefile', '');
      // bodyFormData.append("image1", this.image1);
      bodyFormData.append("image1", null);

      axios({
        method: "post",
        url: "/Api/Web/category/edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              //that.$router.push("/store");
              // that.image1 = null;
              // that.$router.go();
              if (rt.data.IDn) {
                that.cur_category_IDn = rt.data.IDn;
              }
              func(that);
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    get_typed_unit_list(type) {
      var rslt = [];
      if (typeof type == "string") {
        for (var i = 0; i < this.unitlist.length; i++) {
          if (this.unitlist[i]["Type"] == type) {
            rslt.push(JSON.parse(JSON.stringify(this.unitlist[i])));
          }
        }
      }
      else if (typeof type == "object") {
        for (var i = 0; i < this.unitlist.length; i++) {
          for (var j = 0; j < type.length; j++) {
            if (this.unitlist[i]["Type"] == type[j]) {
              rslt.push(JSON.parse(JSON.stringify(this.unitlist[i])));
            }
          }
        }
      }
      return rslt;
    },
    onlyNumber (evt) {
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();;
      } else {
        return true;
      }
    },
    get_price_mark() {
      var price_mark = 1;
      // if (this.cur_product.APrice > 0) price_mark = 1; // A price is always displayed AS MSRP
      if (this.cur_product.BPrice > 0) price_mark = 2;
      if (this.cur_product.CPrice > 0) price_mark = 3;
      if (this.cur_product.DPrice > 0) price_mark = 4;
      if (this.cur_product.EPrice > 0) price_mark = 5;
      if (this.cur_product.FPrice > 0) price_mark = 6;
      if (this.cur_product.GPrice > 0) price_mark = 7;
      if (this.cur_product.HPrice > 0) price_mark = 8;
      if (this.cur_product.IPrice > 0) price_mark = 9;
      // if (this.cur_product.JPrice > 0) price_mark = 10;  // J price is always displayed AS online
      return price_mark;
    },
    get_enable_add_price() {
      var price_mark = 1;
      if (this.cur_product.BPrice > 0) price_mark = 2;
      if (this.cur_product.CPrice > 0) price_mark = 3;
      if (this.cur_product.DPrice > 0) price_mark = 4;
      if (this.cur_product.EPrice > 0) price_mark = 5;
      if (this.cur_product.FPrice > 0) price_mark = 6;
      if (this.cur_product.GPrice > 0) price_mark = 7;
      if (this.cur_product.HPrice > 0) price_mark = 8;
      if (this.cur_product.IPrice > 0) price_mark = 9;
      return price_mark > 0 && price_mark < 9;
    },
    deleteImage() {
      this.cur_product.imagefile = '';
      this.url = '';
    },
    handleCommand(selected) {
      this.per_page = selected;
      return this.get_product_list(1);
    },
    close_alert(countDownChanged) {
      this.countdown = countDownChanged;
      if (this.countdown == 0) {
        this.dismessage = "";
        this.variant = "success";
      }
    },
    category_product_changed() {
      if (this.currpage == "edit" && this.cur_opt == "add") {
        for (var cateIDn in this.categorylist) {
          if (cateIDn == this.cur_product.CategoryIDn) {
            this.cur_product.ModifierGroups =
              this.categorylist[cateIDn].Modifiers;
            break;
          }
        }
      }
    },
    add_product() {
      this.start_editing("add", () => {
        this.cur_product = JSON.parse(JSON.stringify(this.new_product));
        if (this.tax_typelist && Object.keys(this.tax_typelist).length > 0) {
          this.cur_product.TaxTypeIDn = this.tax_typelist[Object.keys(this.tax_typelist)[0]].IDn;
          if(this.is_retail || this.is_wholesale) {
            // Retail mode, set default to Goods
            for (var key in this.tax_typelist) {
              if(this.tax_typelist[key].Name == "Goods") {
                this.cur_product.TaxTypeIDn = this.tax_typelist[key].IDn;
              }
            }
          } else {
            // Restaurant mode, set default to Food
            for (var key in this.tax_typelist) {
              if(this.tax_typelist[key].Name == "Food") {
                this.cur_product.TaxTypeIDn = this.tax_typelist[key].IDn;
              }
            }
          }
        } else {
          this.cur_product.TaxTypeIDn = null;
        }
        this.cur_product_taxTypeIDn = this.cur_product.TaxTypeIDn;
        this.split_period(this.cur_product);
        if (this.cur_department_IDn) {
          this.cur_product.DepartmentIDn = this.cur_department_IDn;
          if (this.cur_category_IDn) {
            this.cur_product.CategoryIDn = this.cur_category_IDn;
          }
        } else if (this.departmentlist) {
          this.cur_product.DepartmentIDn = this.departmentlist[0].IDn;
        }
      });
    },
    find_next_product(row) {
      for (var i = 0; i < this.productlist.length - 1; i++) {
        if (row.IDn == this.productlist[i].IDn) {
          // findout
          i++;
          return this.productlist[i];
        }
      }
      return null;
    },
    find_product(idn) {
      for (var i = 0; i < this.cascader_products.length; i++) {
        if (!this.cascader_products[i].IDn) continue;
        if (idn == this.cascader_products[i].IDn) {
          return this.cascader_products[i];
        }
      }
    },
    find_next_product_2(row) {
      var idn = this.find_next_product_Idn(row.IDn);
      return this.find_product(idn);
    },
    find_next_product_Idn(product_Idn) {
      for (var i = 0; i < this.cascader_options.length; i++) {
        var dopt = this.cascader_options[i];
        for (var j = 0; j < dopt["children"].length; j++) {
          var copt = dopt["children"][j];
          for (var k = 0; k < copt["children"].length; k++) {
            var popt = copt["children"][k];
            if (product_Idn == popt["value"]) {
              var found = true;
              k++;
              break;
            }
          }
          if (!found) { continue; }
          if (k < copt["children"].length) { return copt["children"][k]["value"];}
          else {
            j++;
            break;
          }
        } 
        if (!found) { continue; }
        if (j < dopt["children"].length) { return dopt["children"][j]["children"][0]["value"]; }
        else {
          i++;
          break;
        }
      }
      if (i < this.cascader_options.length) { return this.cascader_options[i]["children"][0]["children"][0]["value"]; }
      return null;
    },
    row_clicked_event(row, column, event) {
      // console.log("row clicked");
      if (!hasUtilityAuth('ProductEdit')) return;
      this.url = "";
      this.cur_product = JSON.parse(JSON.stringify(this.new_product));
      if (
        column &&
        (column.label == this.$t("Tax Class") ||
          column.label == this.$t("Printer") ||
          column.label == this.$t("Price") + " A" ||
          column.label == this.$t("Status") ||
          column.label == this.$t("Discount"))
      ) {
        // Skip some columns by Label
        return;
      }
      this.start_editing("update", () => {
        this.cur_product = JSON.parse(JSON.stringify(row));
        this.format_cur_product();
        this.cur_product_taxTypeIDn = this.cur_product.TaxTypeIDn;
        this.split_period(this.cur_product);
        return row;
      });
    },
    btnselectchange() {
      let color = this.cur_product.BtnColor;
      this.btnselectstyle = { backgroundColor: color };
    },
    form_cancel() {
      // console.log("cancel");
      this.currpage = "list";
      this.url = '';
      this.cur_product = null;
      this.cur_product_taxTypeIDn = null;
      this.image1 = null;
      this.cur_item = null;
      this.editing_idx = -1;
      this.cascader_product = [];
    },
    Back_to_list() {
      this.cur_department_IDn = this.saved_department_IDn;
      this.cur_category_IDn = this.saved_category_IDn;
      this.cur_status = this.saved_status;

      this.get_category_list("backfromlist");
    },
    format_cur_product() {
      this.cur_product.IsOpenPrice = parseInt(this.cur_product.IsOpenPrice);
      this.cur_product.NonRevenue = parseInt(this.cur_product.NonRevenue);
      this.cur_product.Recommended = parseInt(this.cur_product.Recommended);
    },
    cascader_product_change(value) {
      if (value.length > 0) {
        this.start_editing("update", () => {
          if (value.length <= 0) {
            return;
          }
          for (var i = 0; i < this.cascader_products.length; i++) {
            if (!this.cascader_products[i].IDn) continue;
            if (value[2] == this.cascader_products[i].IDn) {
              this.cur_product = JSON.parse(JSON.stringify(this.cascader_products[i]));
              this.format_cur_product();
              this.cur_product_taxTypeIDn = this.cur_product.TaxTypeIDn;
              this.split_period(this.cur_product);
              var row = this.cascader_products[i];
              break;
            }
          }
          if (!this.cur_product) {
            // console.log("Not found: ", value);
            this.form_cancel();
            return;
          }
          if (row) return row;
        });
      } else {
        this.Back_to_list();
      }
    },
    start_editing(opt = "update", func_get_cur_product = () => {}) {
      this.form_cancel();
      var row = func_get_cur_product(this);

      this.saved_department_IDn = this.cur_department_IDn;
      this.saved_category_IDn = this.cur_category_IDn;
      this.saved_status = this.cur_status;

      this.cur_department_IDn = this.cur_product.DepartmentIDn;
      this.cur_category_IDn = this.cur_product.CategoryIDn;
      this.is_vip_category = false;
      if (this.cur_product.DepartmentIDn > 0) {
        for (let i = 0; i < this.departmentlist.length; i++) {
          if (this.cur_product.DepartmentIDn == this.departmentlist[i].IDn
            && this.departmentlist[i].Name1 == "VIP") {
            this.is_vip_category = true;
            break;
          }
        }
      }
      this.btnselectchange();
      this.get_category_list("product_init");
      if (row) this.cascader_product = this.get_cascader_product(row["IDn"]);
      else this.cascader_product = [];
      if (row) this.next_row = this.find_next_product_2(row);
      else this.next_row = null;
      this.url = '';
      this.cur_opt = opt;
      this.currpage = "edit";
    },
    get_cascader_product(product_idn) {
      for (var i = 0; i < this.cascader_options.length; i++) {
        var dept_idn = this.cascader_options[i]["value"];
        for (var j = 0; j < this.cascader_options[i]["children"].length; j++){
          var cate_idn = this.cascader_options[i]["children"][j]["value"];
          for (var k = 0; k < this.cascader_options[i]["children"][j]["children"].length; k++){
            if (product_idn == this.cascader_options[i]["children"][j]["children"][k]["value"]) {
              return [dept_idn, cate_idn, product_idn];
            }
          }
        }
      }
      return [];
    },
    image_upload_input () {
      if (this.image1 && this.image1.size > 7340032) { // 7M=7340032
        this.$refs.image_popover.$emit('open');
        this.image1 = null;
      }
      else {
        this.$refs.image_popover.$emit('close');
        if (this.image1) {
          this.update_url(this.image1);
        }
      }
    },
    async update_url(file) {
      let self = this;
      let reader = new FileReader();
      reader.onload = function() {
        self.url = reader.result;
      };
      reader.readAsDataURL(this.image1);
    },
    async get_product_class(tax_type_idn = 0, func = () => {}) {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("tax_type_idn", tax_type_idn ? tax_type_idn : 0);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/product/get_product_class",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              // that.cur_tax_type_idn = rt.data.tax_type_idn;
              if (rt.data.tax_type_idn > 0) {
                that.cur_tax_type = rt.data.tax_type;
                that.cur_tax_group_list = rt.data.tax_group_list;
                that.cur_country_idn = rt.data.country_idn;
                that.cur_province_idn = rt.data.province_idn;
                that.apply_cur_province_idn(that);
              } else {
                that.cur_tax_type = JSON.parse(JSON.stringify(that.new_tax_type));
                that.cur_tax_group_list = {};
                that.cur_country_idn = rt.data.country_idn;
                that.cur_province_idn = rt.data.province_idn;
                that.apply_cur_province_idn(that);
              }
              that.tax_list = rt.data.tax_list;
              that.country_list = rt.data.country_list;
              that.cur_province_list = [];
              if (that.cur_tax_group && that.cur_tax_group["ProvinceIDn"]){
                for (let i = 0; i < that.country_list.length; i++) {
                  for (let j = 0; j < that.country_list[i]["Provinces"].length; j++) {
                    if (that.country_list[i]["Provinces"][j]["IDn"] == that.cur_tax_group["ProvinceIDn"]){
                      that.cur_province_list = JSON.parse(JSON.stringify(that.country_list[i]["Provinces"]));
                      break;
                    }
                  }
                }
              }
              // let tmp_list = that.cur_tax_group["TaxList"].split[","];
              that.get_cur_tax_checked(that);
              func(that);
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async save_product_class(func = () => {}) {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      if (this.cur_tax_type) {
        bodyFormData.append("tax_type", JSON.stringify(this.cur_tax_type));
      }
      if (this.cur_tax_group_list) {
        bodyFormData.append("tax_group_list", JSON.stringify(this.cur_tax_group_list));
      }

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/product/save_product_class",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_product.TaxTypeIDn = parseInt(rt.data.tax_type_idn);
              // that.cur_product = that.duplicate_object(that.cur_product);
              that.tax_typelist = rt.data.tax_types;
              func(that);
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async change_row_property(row, attr) {
      if (!row.DepartmentIDn) {
        alert($t("Product add/edit requires a valid Department"));
      }
      if (!row.CategoryIDn) {
        alert($t("Product add/edit requires a valid Category"));
      }
      if (!row.Name1) {
        alert($t("Product add/edit requires a valid Name1"));
      }
      if (!row.TaxTypeIDn) {
        alert($t("Product add/edit requires a valid Tax Type"));
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", "update");
      var that = this;
      bodyFormData.append("UpdateBy", this.user_id);
      bodyFormData.append("IDn", row["IDn"]);
      bodyFormData.append(attr, row[attr]);

      axios({
        method: "post",
        url: "/Api/Web/product/edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.dismessage = that.$t("Data Updated Successfully");
              that.countdown = 2;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },    
    async form_submit(event, dosomething) {
      if (event) {
        event.preventDefault();
      }
      if (!this.cur_product.DepartmentIDn) {
        alert($t("Product add/edit requires a valid Department"));
      }
      if (!this.cur_product.CategoryIDn) {
        alert($t("Product add/edit requires a valid Category"));
      }
      if (!this.cur_product.Name1) {
        alert($t("Product add/edit requires a valid Name1"));
      }
      if (!this.cur_product.TaxTypeIDn) {
        alert($t("Product add/edit requires a valid Tax Type"));
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("opt", this.cur_opt);
      var that = this;
      for (var key in this.cur_product) {
        if (key == "IDn" && this.cur_product[key] < 0) {
          // any IDn that is changed and supposed to be 0, is changed back to 0.
          bodyFormData.append(key, 0);
        } else if (key == "IsCombo") {
          if (this.cur_product[key]) {
            bodyFormData.append(key, 1);
          } else {
            bodyFormData.append(key, 0);
          }
        } else if (key == "NonRevenue") {
          if (this.cur_product[key]) {
            bodyFormData.append(key, 1);
          } else {
            bodyFormData.append(key, 0);
          }
        } else if (key == "Recommended") {
          if (this.cur_product[key]) {
            bodyFormData.append(key, 1);
          } else {
            bodyFormData.append(key, 0);
          }
        } else if (key == "IsOpenPrice") {
          if (this.cur_product[key]) {
            bodyFormData.append(key, 1);
          } else {
            bodyFormData.append(key, 0);
          }
        } else if (key == "Status") {
          if (this.cur_product[key] == "Deactive") {
            bodyFormData.append(key, "Deactive");
          } else if (this.cur_product[key] == "Modifier") {
            bodyFormData.append(key, "Modifier");
          } else {
            bodyFormData.append(key, "Active");
          }
        } else if (key == "Weigh") {
          if (this.cur_product[key] == "Y") {
            bodyFormData.append(key, "Y");
          } else {
            bodyFormData.append(key, "N");
          }
        } else if (key == "HasSerialNumber") {
          if (this.cur_product[key]) {
            bodyFormData.append(key, 1);
          } else {
            bodyFormData.append(key, 0);
          }
        } else if (key == "InventoryAutoUpdate") {
          if (this.cur_product[key]) {
            bodyFormData.append(key, 1);
          } else {
            bodyFormData.append(key, 0);
          }
        } else if (key == "OnlineProduct") {
          if (this.cur_product[key] == "True") {
            bodyFormData.append(key, "True");
          } else {
            bodyFormData.append(key, "False");
          }
        } else if (key == "FixPrice") {
          if (this.cur_product[key]) {
            bodyFormData.append(key, 1);
          } else {
            bodyFormData.append(key, 0);
          }
        } else if (key == "AisFixed") {
          if (this.cur_product[key]) {
            bodyFormData.append(key, 1);
          } else {
            bodyFormData.append(key, 0);
          }
        } else if (key == "BisFixed") {
          if (this.cur_product[key]) {
            bodyFormData.append(key, 1);
          } else {
            bodyFormData.append(key, 0);
          }
        } else if (key == "CisFixed") {
          if (this.cur_product[key]) {
            bodyFormData.append(key, 1);
          } else {
            bodyFormData.append(key, 0);
          }
        } else if (key == "DisFixed") {
          if (this.cur_product[key]) {
            bodyFormData.append(key, 1);
          } else {
            bodyFormData.append(key, 0);
          }
        } else if (key == "EisFixed") {
          if (this.cur_product[key]) {
            bodyFormData.append(key, 1);
          } else {
            bodyFormData.append(key, 0);
          }
        } else if (key == "FisFixed") {
          if (this.cur_product[key]) {
            bodyFormData.append(key, 1);
          } else {
            bodyFormData.append(key, 0);
          }
        } else if (key == "GisFixed") {
          if (this.cur_product[key]) {
            bodyFormData.append(key, 1);
          } else {
            bodyFormData.append(key, 0);
          }
        } else if (key == "HisFixed") {
          if (this.cur_product[key]) {
            bodyFormData.append(key, 1);
          } else {
            bodyFormData.append(key, 0);
          }
        } else if (key == "IisFixed") {
          if (this.cur_product[key]) {
            bodyFormData.append(key, 1);
          } else {
            bodyFormData.append(key, 0);
          }
        } else if (key == "JisFixed") {
          if (this.cur_product[key]) {
            bodyFormData.append(key, 1);
          } else {
            bodyFormData.append(key, 0);
          }
        } else if (key == "OnlineShowPrice") {
          if (this.cur_product[key] == "False") {
            bodyFormData.append(key, "False");
          } else {
            bodyFormData.append(key, "True");
          }
        } else if (key == "Discountable") {
          if (this.cur_product[key] == "False") {
            bodyFormData.append(key, "False");
          } else {
            bodyFormData.append(key, "True");
          }
        } else {
          bodyFormData.append(key, this.cur_product[key]);
        }
        // else {
        //   console.log(key, this.cur_product[key]);
        // }
      }
      if (this.cur_opt == "add") {
        bodyFormData.append("CreateBy", this.user_id);
      }
      bodyFormData.append("UpdateBy", this.user_id);
      
      if (!this.cur_product.imagefile) bodyFormData.append('imagefile', '');
      bodyFormData.append("image1", this.image1);

      axios({
        method: "post",
        url: "/Api/Web/product/edit",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.cur_department_IDn = that.saved_department_IDn;
              that.cur_category_IDn = that.saved_category_IDn;
              that.cur_status = that.saved_status;

              that.dismessage = that.$t("Data Updated Successfully");
              that.countdown = 3;
              if (dosomething && that.next_row) {
                return that.row_clicked_event(that.next_row, event, null);
              }
              if (that.cur_opt == "add") {
                that.get_product_list(1);
              } else {
                that.get_product_list(2);
              }
              //that.$router.push("/store");
              that.image1 = null;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async get_category_list(from) {
      // this will be called only after form is valid. You can do api call here to login
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      this.is_vip_category = false;
      if (this.add_cate) {
        this.cur_category.DepartmentIDn = this.cur_department_IDn;
      }
      if (from == "list" || from == "backfromlist") {
        if (this.cur_department_IDn > 0) {
          bodyFormData.append("DepartmentIDn", this.cur_department_IDn);
          for (let i = 0; i < this.departmentlist.length; i++) {
            if (this.cur_department_IDn == this.departmentlist[i].IDn
              && this.departmentlist[i].Name1 == "VIP") {
              this.is_vip_category = true;
              break;
            }
          }
        } else if (this.cur_department_IDn == -1) {
          this.start_dept();
          return;
        }
      } else {
        if (this.cur_product.DepartmentIDn > 0) {
          bodyFormData.append("DepartmentIDn", this.cur_product.DepartmentIDn);
          let i;
          for (i = 0; i < this.departmentlist.length; i++) {
            if (this.cur_product.DepartmentIDn == this.departmentlist[i].IDn
              && this.departmentlist[i].Name1 == "VIP") {
              this.is_vip_category = true;
              break;
            }
          }
          if (this.cur_product.IDn <= 0) {
            if (i < this.departmentlist.length) {
              if (this.tax_typelist && Object.keys(this.tax_typelist).length > 0) {
                this.cur_product.TaxTypeIDn = this.tax_typelist[Object.keys(this.tax_typelist)[0]].IDn;
              } else {
                this.cur_product.TaxTypeIDn = null;
              }
              this.cur_product_taxTypeIDn = this.cur_product.TaxTypeIDn;
              this.cur_product.NonRevenue = 1;
            } else {
              // when not found in loop
              this.cur_product.NonRevenue = 0;
            }
          }
        }
      }

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Category/search",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              if (from == "list") {
                that.search_categorylist = rt.data.categorys;
                that.cur_category_IDn = 0;
                that.cur_status = 0;
                that.get_product_list(0);
                // for the transfer of cur_category_IDn when return from product page back to list page
              } else if (from == "backfromlist") {
                that.search_categorylist = rt.data.categorys;
                // refresh list by given cur_category_IDn
                that.get_product_list(1);
              } else {
                that.product_categorylist = rt.data.categorys;
                if (!that.cur_product.CategoryIDn) {
                  if (that.cur_category_IDn) {
                    that.cur_product.CategoryIDn = that.cur_category_IDn;
                  } else if (rt.data.CategoryIDn) {
                    that.cur_product.CategoryIDn = rt.data.CategoryIDn;
                  }
                }
                if (from == "product_init") that.get_product_list(2);
              }
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    get_product_list_delay() {
      if (this.delay_timer) {
        clearTimeout(this.delay_timer);
        this.delay_timer = null;
      }
      // if (this.cur_name.length < 3) {
      //   return;
      // }
      var that = this;
      this.delay_timer = setTimeout(() => {
        that.get_product_list(1);
      }, 400);
    },
    async get_product_list(golist) {
      if (this.delay_timer) {
        clearTimeout(this.delay_timer);
        this.delay_timer = null;
      }
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      if (this.cur_department_IDn) {
        bodyFormData.append("DepartmentIDn", this.cur_department_IDn);
      }
      if (this.cur_category_IDn > 0) {
        bodyFormData.append("CategoryIDn", this.cur_category_IDn);
      } else if (this.cur_category_IDn == -1) {
        this.start_cate();
        return;
      }
      if (this.cur_status) {
          bodyFormData.append("Status", this.cur_status);
      }
      if (this.cur_product_IDn) {
        bodyFormData.append("ProductIDn", this.cur_product_IDn);
      }
      if (this.cur_name) {
        bodyFormData.append("Name", this.cur_name);
      }
      bodyFormData.append("page", this.current_page);
      bodyFormData.append("per_page", this.per_page);
      if (this.PrinterIDn) {
        bodyFormData.append("PrinterIDn", this.PrinterIDn);
      }
      if (this.IsCombo >= 0) {
        bodyFormData.append("IsCombo", this.IsCombo);
      }

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Product",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.departmentlist = rt.data.departments;
              that.search_categorylist = rt.data.categorys;
              that.categorylist = rt.data.full_indexed_categorys;
              that.printerlist = rt.data.printers;
              that.modifierlist = rt.data.modifiers;
              that.tax_typelist = rt.data.tax_types;
              that.productlist = rt.data.products;
              that.product_total = rt.data.product_total;
              that.per_page = rt.data.per_page;
              that.cascader_options = rt.data.cascader_options;
              that.cascader_products = rt.data.cascader_products;
              that.unitlist = rt.data.unitlist;
              if (golist == 0) {
                that.next_row = null;
                that.cascader_product = [];
              }
              if (golist == 1) {
                that.form_cancel();
              }
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async upload_ldw() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("ProductIDn", this.cur_product.IDn);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Pos_setting/uploadLdwImage",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              alert(rt.data.message);
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async get_ldw() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Pos_setting/getLdwCode",
        data: bodyFormData,
      })
        .then(function (response) {
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              var ldw = rt.data.ldw;
              if (ldw && (ldw.length > 0)) {
                that.hasLdwUpload = true;
              }
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          } else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async init_data() {
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      this.is_retail = getStore("is_retail");
      this.is_wholesale = getStore("is_wholesale");
      if (!this.token || !this.user_id || this.is_retail) {
        this.$router.push("/login");
        return;
      }
      this.get_ldw();
      return this.get_product_list(1);
    },
    add_price() {
      if (this.price_mark == 1) this.cur_product.BPrice = "1.00";
      else if (this.price_mark == 2) this.cur_product.CPrice = "1.00";
      else if (this.price_mark == 3) this.cur_product.DPrice = "1.00";
      else if (this.price_mark == 4) this.cur_product.EPrice = "1.00";
      else if (this.price_mark == 5) this.cur_product.FPrice = "1.00";
      else if (this.price_mark == 6) this.cur_product.GPrice = "1.00";
      else if (this.price_mark == 7) this.cur_product.HPrice = "1.00";
      else if (this.price_mark == 8) this.cur_product.IPrice = "1.00";
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style>
.content {
  padding-top: 40px;
}
.search-button {
  margin-top: 2rem;
}
.select_text {
  margin-left: -1rem;
}
.card .table td {
  padding: 0.5rem;
}
.picimg {
  max-height: 14rem;
}
.el-collapse-item__header {
  background-color: #a5d1fa;
  font-size: 1rem;
  padding-left: 2rem;
  height: 2rem;
  border-radius: 0.75rem 0.75rem 0 0;
}
.el-collapse-item__content {
  padding-bottom: 0.5rem;
}
.form-group {
  margin-bottom: 0.5rem;
}
.el-cascader-menu {
  height: 500px;
  max-height: none;
}
.el-cascader-menu__wrap.el-scrollbar__wrap {
  height: 500px;
}
span.el-cascader {
  width: 100%;
}
span.el-cascader div.el-input.el-input--suffix {
  width: 100%;
  height: calc(1.5em + 1.25rem + 2px);
  font-weight: 400;
  color: #32325d;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.375rem;
  box-shadow: none;
}
span.el-cascader div.el-input.el-input--suffix input.el-input__inner {
  border: 0px;
}

.el-main {
  background-color: #E9EEF3;
  /* color: #333; */
  text-align: center;
  line-height: 15rem;
  padding: 0;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.upload_ldw {
  float: right;
}
.calculating-sign {
  text-align: center;
  padding-top: 10px;
}
.grid-header-row, .grid-row {
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}
.grid-header-row {
  padding-top: 1rem;
}
.grid-checkbox {
  text-align: center;
  padding-top: 10px;
}
.grid-checkbox .el-checkbox__inner {
  width: 20px;
  height: 20px;
}
.grid-checkbox .el-checkbox__inner::after {
  height: 12px;
  left: 7px;
}
.layout-row {
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}
.disabled-input {
  background-color: #fff !important;
}
.disabled-input:hover {
  background-color: #e9ecef !important;
  color: darkgray !important;
}
.data-grid .el-table__body-wrapper .el-table__body .el-table__row td {
  padding-left: 0;
  padding-right: 0;
}
.data-grid .el-table__fixed-body-wrapper .el-table__body .el-table__row td {
  padding-left: 0;
  padding-right: 0;
}
.data-grid .el-table__body-wrapper .el-table__body .el-table__row .cell {
  text-align: center;
}
.data-grid .el-table__fixed-body-wrapper .el-table__body .el-table__row .cell {
  text-align: center;
}
.el-table tr.cur-product-row {
  background-color: #7DC4FB;
}
.add-option,.remove-option {
  color: #666666;
  font-weight: bold;
}

.el-checkbox-group .w100.el-checkbox-button,
.el-checkbox-group .w100.el-checkbox-button .el-checkbox-button__inner {
  width: 100%;
}

span.el-tag {
  margin: 3px;
  color: #409EFF;
  border-color: rgba(64,158,255,.2);
  background-color: rgba(64,158,255,.1);
}
span.el-tag:hover {
  color: #409EFF;
  border-color: rgba(64,158,255,.3);
  background-color: rgba(64,158,255,.2);
}
hr {
  margin-top: 0;
  margin-bottom: 1rem;
}

#modal_productclass .modal-title {
  font-size: 1.2rem;
  font-weight: 900;
}

#modal_productclass .modal-header {
  padding-bottom: 0;
}

#modal_productclass .modal-body {
  padding-top: 0.5rem;
  padding-bottom: 1.5rem;
}

.required>.form-group>div {
  border: 2px solid red;
}
label.step {
  font-weight: 900;
  color: black;
}

span.tax-tag {
  color: var(--color);
  border-color: var(--border-color);
  background-color: var(--background-color);
}
span.tax-tag:hover {
  color: var(--color);
  border-color: var(--border-color-hover);
  background-color: var(--background-color-hover);
}
</style>
